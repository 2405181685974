<script setup lang="ts">
import dayjs from "dayjs";

const props = defineProps<{
	entries: number;
	finishedAt: string;
}>();

const { t } = useT();
const localPath = useLocalePath();

const handleClick = () => {
	navigateTo(localPath("/issues/all-games/"));
};

const convertFinishedAt = computed(() => convertDateTimeFormat(props.finishedAt || ""));

const timeRemaining = computed(() =>
	dayjs(convertDateTimeFormat(convertFinishedAt.value)).diff(dayjs(), "d") > 1
		? "D[d] HH[h] mm[m]"
		: "HH[h] mm[m] ss[s]"
);

const { durationLeft } = useCountdown({
	timestamp: convertFinishedAt.value,
	formatToken: timeRemaining.value
});
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 135,
			height: 246,
			src: '/nuxt-img/prize-drops/promo.png',
			alt: 'prize-logo',
			format: 'avif',
			loading: 'lazy'
		}"
		class="card-prize-drops"
		width="328px"
		height="246px"
		@click="handleClick"
	>
		<template #default>
			<div class="free">
				<ASvg name="48/free" class="icon" />
				<AText class="text-tlalnepantla free-text text-carabanchel" :modifiers="['semibold', 'uppercase']">
					{{ t("Free") }}
				</AText>
			</div>
		</template>
		<template #top>
			<div class="badge-wrapper" />
			<AText as="div" variant="topeka" :modifiers="['bold', 'uppercase']">
				{{ t("Mad Drops") }}
			</AText>
			<AText variant="topeka" :modifiers="['semibold']" class="label-time">
				{{ t("Ends in:") }}
				<AText :modifiers="['extrabold']" class="label-time" gradient="var(--goeteborg)">
					<span>{{ durationLeft }}</span>
				</AText>
			</AText>
			<AText as="div" variant="topeka" :modifiers="['semibold']">
				{{ t("Every day we give away") }}
			</AText>
			<MPrizeFund variant="entries" img="/nuxt-img/prizes/citycoins@2x.png" :icon-size="24">
				<AText variant="tanzay" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase']">
					{{ numberFormat(entries) }}
				</AText>
			</MPrizeFund>
		</template>
		<template #bottom>
			<AButton variant="primary" class="button" size="s" data-tid="promo-play-prizes">
				<AText class="text-tlalnepantla" :modifiers="['semibold']">
					{{ t("Play for Prizes") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	background: var(--geneve);

	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
		gap: 8px;
	}

	&:deep(.prize) {
		img {
			filter: drop-shadow(0 0 6px var(--сirebon));
		}
	}
}

.badge-wrapper {
	min-height: 22px;
}

.text-count {
	text-shadow: 0 4px 4px var(--coronel);
}

.button {
	min-width: 140px;
	width: max-content;
	gap: 2px;
}

.free {
	position: absolute;
	right: 12px;
	top: 0;
	width: 60px;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 3px 16px 0;

	&:deep(svg) {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&-text {
		position: relative;
		z-index: 2;
	}
}
</style>
